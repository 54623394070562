<template>
  <div id="mrf-report">
    <v-overlay :value="OverlayLoadingFlag">
      <div class="text-center loading-text">
        <p>{{ OverlayLoadingText }}</p>
        <p></p>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Area
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  @change="getZoneCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="AreaCodeOptionsLoading"
                  :items="AreaCodeOptions"
                  v-model="AreaCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Zone
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  @change="getRegionCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Region
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="RegionCodeOptionsLoading"
                  :items="RegionCodeOptions"
                  v-model="RegionCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Lom
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  v-if="ExcelDownloadFlag"
                  color="info"
                  @click.prevent="
                    generateExcel(
                      tableData1,
                      tableOptions1.ExcelFields,
                      tableOptions1.ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="mr-4"
                  >Download</v-btn
                >
              </v-col>
              <p></p>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap v-if="LomCode > 0">
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="GenerateMrfButtonFlag"
                :disabled="!valid1"
                @click.prevent="generateManualMrf"
                color="#8a2be2"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Generate Manual MRF</v-btn
              >
            </v-col>
          </v-row>
          <br />
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>{{ tableData1.length }} Records found.</h4>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12" v-if="TableFlag">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import PdfDocumentFilePreview from "@/view/components/PdfDocumentFilePreview.vue";
import UploadManualMrf from "@/view/pages/erp/reports/UploadManualMrf.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    PdfDocumentFilePreview,
    UploadManualMrf,
  },
  data() {
    return {
      LoadingFlag: false,
      uploadPercentage: 0,
      previewRecordPrompt: false,
      ExcelDownloadFlag: false,
      ExcelFileName: "",
      tableOptions1: [],
      tableData1: [],

      TableFlag: false,

      GenerateMrfButtonFlag: false,
      UploadMrfButtonFlag: false,

      MrfDetail: {},
      UploadMrfFlag: false,
      UploadMrfPageTitle: "",

      PageTitle: "",
      PageDescription: "",

      alert: {},
      selected: {},

      valid1: true,
      OverlayLoadingFlag: false,
      OverlayLoadingText: "",

      JciYearCode: "",

      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileFlag: false,

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,
      RoleId: "",

      PageInfo: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        // RoleId = 4;
        this.GenerateMrfButtonFlag = RoleId == 2 ? true : false;
        this.UploadMrfButtonFlag = RoleId == 2 ? true : false;

        this.pageData();
      }
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "np_report",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getAreaCodeOptions();
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var message = "";

        var YearCode = this.JciYearCode;
        console.log({ YearCode });

        var AreaCode = this.AreaCode;
        console.log({ AreaCode });

        var ZoneCode = this.ZoneCode;
        console.log({ ZoneCode });

        var RegionCode = this.RegionCode;
        console.log({ RegionCode });

        var LomCode = this.LomCode;
        console.log({ LomCode });

        this.OverlayLoadingFlag = true;

        this.tableData1 = [];
        this.ExcelDownloadFlag = false;
        this.ExcelFileName = "";
        this.tableData1 = [];
        this.Modules = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/np-report/consolidate-jci-members";
        var upload = {
          UserInterface: 1,
          Year: YearCode,
          Area: AreaCode,
          Zone: ZoneCode,
          Region: RegionCode,
          Lom: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              var options = records.TableOptions;
              console.log({ options });

              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableOptions1 = options;
              thisIns.ExcelDownloadFlag = options.DownloadFlag;
              thisIns.tableData1 = records.TableData;
              thisIns.TableFlag = records.TableData.TableFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;

              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      }
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        YearCode: this.JciYearCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.tableData1 = [];
      this.ZoneCode = "";
      this.RegionCode = "";
      this.LomCode = "";
      this.ZoneCodeOptions = [];
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 6,
          AreaCode: areaCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.tableData1 = [];
      this.RegionCode = "";
      this.LomCode = "";
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.RegionCode = "";
        this.RegionCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.tableData1 = [];
      this.LomCode = "";
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "" && RegionCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 4,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          RegionCode: RegionCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (RegionCode == "") {
          message += "Region should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#mrf-report {
  .loading-text {
    font-size: 18px;
  }
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>